<template>
  <v-dialog v-model="show" max-width="500px">
    <v-card>
      <v-card-title>
        <span class="headline">Orders Bulk Upload</span>
      </v-card-title>
      <v-card-text>
        <v-container class="mt-3">
          <v-row>
            <v-file-input v-model="file" label="Orders File (.csv)" />
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="blue darken-1" text @click="show = false"> Cancel </v-btn>
        <v-btn color="blue darken-1" text @click="upload"> Upload </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";

export default {
  name: "DialogUpload",
  props: ["value"],
  data: () => ({ file: [] }),
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    upload() {
      this.show = false;
      if (this.file) {
        this.$emit("uploadStarted");
        var bodyFormData = new FormData();
        bodyFormData.append("File", this.file);
        this.$http
          .post("Orders/Upload", bodyFormData)
          .then((response) => {
            this.$emit("uploadFinished");
            this.isLoading = false;
            if (response.data.success) {
              if (
                response.data.data.isSuccess &&
                !response.data.isErrorCsvLines
              ) {
                this.$toast.success(response.data.data.message, "Success", {
                  position: "topRight",
                });
              } else {
                if (response.data.data.isErrorCsvLines) {
                  let records = [
                    ...response.data.data.csvLines,
                    ...response.data.data.notValidProductsList,
                    ...response.data.data.notExistProductsList,
                  ];

                  this.downloadCsvError(response.data.data.fileName, records);
                }
                this.$toast.error(response.data.data.message, "Error", {
                  position: "topRight",
                });
              }
            } else {
              this.$toast.error(response.data.message, "Error", {
                position: "topRight",
              });
            }
          })
          .catch((error) => {
            this.$emit("uploadFinished");
            this.$toast.error(
              "Something went wrong. please try again later",
              "Error",
              {
                position: "topRight",
              }
            );
          });
      } else {
        this.$toast.error("No file selected.", "Error", {
          position: "topRight",
        });
      }
      this.file = [];
    },
    downloadCsvError(filename, csvErrorLines) {
      var fileUrl = window.URL.createObjectURL(new Blob([csvErrorLines]));
      var fileLink = document.createElement("a");
      fileLink.href = fileUrl;
      fileLink.setAttribute(
        "download",
        "myOrders_CSV_Errors_" +
          this.$store.state.authUser.username +
          "_" +
          moment().format("h:mm:ss a DD.MM.YYYY") +
          "_" +
          filename
      );
      document.body.appendChild(fileLink);
      fileLink.click();
    },
  },
};
</script>

<style>
</style>