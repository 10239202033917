var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.boxes,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.totalBoxes,"expanded":_vm.boxExpanded,"item-key":"orderBoxId","single-expand":"","show-expand":"","footer-props":{
    showFirstLastPage: true,
    'items-per-page-options': [5, 10, 15],
  },"hide-default-footer":_vm.hideTableFooter},on:{"update:options":function($event){_vm.options=$event},"update:expanded":function($event){_vm.boxExpanded=$event},"item-expanded":function (data) { return _vm.getOrderLines(data); }},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
  var item = ref.item;
return [_c('v-btn',{attrs:{"color":"error","fab":"","small":""},on:{"click":function($event){return _vm.deleteBox(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}},{key:"item.status",fn:function(ref){
  var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('v-chip',_vm._g({staticClass:"ma-2",attrs:{"text-color":"white","v-if":item.status,"color":item.status
              ? _vm.status_list.find(function (x) { return x.id == item.status; }).code
              : ''}},on),[_vm._v(" "+_vm._s(item.status ? _vm.status_list.find(function (x) { return x.id == item.status; }).statusInText : "")+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.statusFullForm(item.status ? item.status : 0)))])])]}},{key:"item.createdAt",fn:function(ref){
              var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.dateFormat(item.createdAt)))])]}},{key:"item.instructions",fn:function(ref){
              var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-chip',_vm._g({attrs:{"text-color":"white","color":"primary"}},on),[_vm._v(" "+_vm._s(item.instructions ? item.instructions.length : 0)+" ")])],1)]}}],null,true)},[(_vm.showInstructions(item))?_c('span',{domProps:{"innerHTML":_vm._s(item.instruction)}}):_vm._e()])]}},{key:"expanded-item",fn:function(ref){
              var headers = ref.headers;
              var item = ref.item;
return [_c('td',{staticClass:"primary pa-1",attrs:{"colspan":headers.length}},[_c('OrderLinesTable',{attrs:{"box-id":item.orderBoxId,"zones":_vm.zones,"status_list":_vm.status_list,"loading":_vm.orderLineLoading,"queue":_vm.queue},model:{value:(item.orderLines),callback:function ($$v) {_vm.$set(item, "orderLines", $$v)},expression:"item.orderLines"}})],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }