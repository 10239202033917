<template>
  <v-data-table
    :headers="headers"
    :items="boxes"
    :loading="loading"
    :options.sync="options"
    :server-items-length="totalBoxes"
    :expanded.sync="boxExpanded"
    item-key="orderBoxId"
    single-expand
    show-expand
    :footer-props="{
      showFirstLastPage: true,
      'items-per-page-options': [5, 10, 15],
    }"
    :hide-default-footer="hideTableFooter"
    @item-expanded="(data) => getOrderLines(data)"
  >
    <template v-slot:[`item.actions`]="{ item }">
      <v-btn color="error" fab small @click="deleteBox(item)">
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </template>
    <template v-slot:[`item.status`]="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-chip
            class="ma-2"
            text-color="white"
            :v-if="item.status"
            :color="
              item.status
                ? status_list.find((x) => x.id == item.status).code
                : ''
            "
            v-on="on"
          >
            {{
              item.status
                ? status_list.find((x) => x.id == item.status).statusInText
                : ""
            }}
          </v-chip>
        </template>
        <span>{{ statusFullForm(item.status ? item.status : 0) }}</span>
      </v-tooltip>
    </template>
    <template v-slot:[`item.createdAt`]="{ item }">
      <div>{{ dateFormat(item.createdAt) }}</div>
    </template>
    <template v-slot:[`item.instructions`]="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            <v-chip text-color="white" color="primary" v-on="on">
              {{ item.instructions ? item.instructions.length : 0 }}
            </v-chip>
          </span>
        </template>
        <span v-if="showInstructions(item)" v-html="item.instruction" />
      </v-tooltip>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length" class="primary pa-1">
        <OrderLinesTable
          v-model="item.orderLines"
          :box-id="item.orderBoxId"
          :zones="zones"
          :status_list="status_list"
          :loading="orderLineLoading"
          :queue="queue"
        />
      </td>
    </template>
  </v-data-table>
</template>

<script>
import OrderLinesTable from "./OrdersLinesTable.vue";
import moment from "moment";

export default {
  name: "OrderBoxesTable",
  components: { OrderLinesTable },
  props: {
    value: Array,
    headers: {
      type: Array,
      default: () => [
        {
          text: "Box Number",
          sortable: false,
          value: "orderBoxId",
        },
        {
          text: "Status",
          align: "center",
          value: "status",
          sortable: false,
        },
        {
          text: "Instructions",
          align: "center",
          value: "instructions",
          sortable: false,
        },
        {
          text: "Products",
          align: "center",
          sortable: false,
          value: "noOfOrderLines",
        },
        {
          text: "Meals",
          align: "center",
          sortable: false,
          value: "noOfMeals",
        },
      ],
    },
    status_list: Array,
    zones: Array,
    loading: Boolean,
    search_field: String,
    search_value: String,
    queue: String,
  },
  data: () => ({
    boxExpanded: [],
    orderLineLoading: false,
    options: {},
    paginatedBoxes: [],
    totalBoxes: 0,
  }),
  computed: {
    hideTableFooter() {
      return this.search_value ? false : true;
    },
    boxes: {
      get() {
        if (!this.search_value) {
          this.totalBoxes = this.value ? this.value.length : 0;
          return this.value;
        } else {
          return this.paginatedBoxes;
        }
      },
      set() {
        this.$emit("input", this.value);
      },
    },
  },
  watch: {
    options: {
      handler() {
        if (!this.options.isSearch) {
          this.getBoxes();
        }
      },
      deep: true,
    },
  },
  created() {
    this.$ordersHub.$on("status-changed", this.onStatusChanged);
  },
  beforeDestroy() {
    this.$ordersHub.$off("status-changed", this.onStatusChanged);
  },
  methods: {
    showInstructions(item) {
      if (!item.instructions) {
        this.$http
          .post(`Orders/Boxes/${item.orderBoxId}/Instructions`)
          .then((response) => {
            let _instructions = response.data.data;
            if (_instructions && _instructions.length > 0) {
              let boxInstructions = [];
              _instructions.forEach((x) => {
                if (
                  !boxInstructions.some(
                    (y) =>
                      y.bayNumber === x.bayNumber &&
                      y.instruction === x.instruction
                  )
                ) {
                  boxInstructions.push({
                    bayNumber: x.bayNumber,
                    instruction: x.instruction,
                  });
                }
              });
              item.instructions = boxInstructions;
            } else {
              item.instructions = [];
            }
            item.instruction = item.instructions.reduce((instruction, item) => {
              return (instruction += `Bay ${item.bayNumber}: ${item.instruction}<br>`);
            }, "");
          });
      }
    },
    dateFormat(date) {
      if (date) {
        return moment.utc(date).local().format("h:mm:ss a DD.MM.YYYY");
      } else {
        return "";
      }
    },
    getBoxes() {
      if (this.search_field === "OrderBoxStatus") {
        this.search_field = "Status";
      }
      if (this.search_value) {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;
        let data = {
          pageNumber: page,
          pageSize: itemsPerPage,
          search: this.search_value,
          searchColumn: this.search_field,
        };
        this.$http.post("Orders/Boxes/Paginated", data).then((response) => {
          this.paginatedBoxes = response.data.data.items;
          this.totalBoxes = response.data.data.totalCount;
        });
      }
    },
    getOrderLines(data) {
      if (
        data.value &&
        !(data.item.orderLines && data.item.orderLines.length > 0)
      ) {
        this.orderLineLoading = true;
        let orderBoxId = data.item.orderBoxId;
        if (orderBoxId) {
          this.$http
            .post(`Orders/Boxes/${orderBoxId}/OrderLines`)
            .then((response) => {
              let orderLines = response.data.data;
              if (orderLines) {
                data.item.orderLines = orderLines;
                let total = orderLines.reduce((meals, orderLine) => {
                  meals += orderLine.quantity;
                  return meals;
                }, 0);
                if (total) {
                  data.item.noOfMeals = total;
                }
              }
              this.orderLineLoading = false;
            })
            .catch(() => {
              this.orderLineLoading = false;
            });
        }
      }
    },

    statusFullForm(id) {
      var status = "";
      switch (id) {
        case 1:
          status = "Waiting to be picked";
          break;
        case 2:
          status = "Partially picked";
          break;
        case 3:
          status = "Order/line picked";
          break;
        case 4:
          status = "Order passed Final Station";
          break;
        case 5:
          status = "Order completed at the warehouse";
          break;
        case 6:
          status = "Order dispatched from the warehouse";
          break;
        case 7:
          status = "Order delivered to the customer";
          break;
      }
      return status;
    },
    onStatusChanged(data) {
      let index = this.boxes.findIndex((x) => x.orderBoxId === data.BoxId);

      if (index > -1) {
        let obj = this.boxes[index];

        if (data.Status === 4) {
          obj.status = 4;
        } else {
          let orderLinesLength = obj.orderLines.length;
          let pickedOrderLinesLength = obj.orderLines.filter(
            (x) => x.status === 3
          ).length;

          if (orderLinesLength === pickedOrderLinesLength) {
            obj.status = 3;
          }
        }

        this.boxes.splice(index, 1, obj);
      }
    },
  },
};
</script>

<style>
</style>