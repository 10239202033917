<template>
  <v-container id="data-tables-view" fluid tag="section">
    <loading
      :active.sync="isLoading"
      color="#9de35c"
      loader="bars"
      :width="loadingSize"
      :height="loadingSize"
      :is-full-page="loadingFullPage"
    />
    <!-- <v-col class="text-right ma-0 pa-0">
      <app-btn
        class="mr-10 ml-2"
        color="warning"
        @click.native="$router.push('/secondary-orders')"
      >
        <v-icon>mdi-swap-horizontal-bold</v-icon> ARCHIVED Orders
      </app-btn>
    </v-col> -->
    <v-col class="text-right">
      <!-- <app-btn class="mx-2" @click="dialogFtpUpdate = true">
        <v-icon>mdi-view-grid-plus</v-icon> Update to sftp
      </app-btn> -->
      <app-btn @click="dialogUpload = true">
        <v-icon>mdi-view-grid-plus</v-icon> Orders Bulk Upload
      </app-btn>
      <app-btn class="mr-7 ml-2" @click.native="$router.push('/csvErrors')">
        <v-icon>mdi-view-grid-plus</v-icon> Csv Errors
      </app-btn>
    </v-col>

    <material-card
      icon="mdi-clipboard-list"
      icon-small
      color="primary"
      title="Orders"
    >
      <v-card-text>
        <v-row>
          <v-col>
            <v-row class="ml-auto" style="max-width: 500px">
              <v-col>
                <v-autocomplete
                  v-model="fieldName"
                  :items="fields"
                  item-text="text"
                  item-value="value"
                  label="Field Name"
                  single-line
                />
              </v-col>
              <v-col>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  hide-details
                  label="Search records"
                  single-line
                  @click:append="searchFunction()"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-divider />
        <v-data-table
          v-if="isViewTable('orders')"
          :headers="ordersHeaders"
          :items="orders"
          :options.sync="options"
          :server-items-length="totalOrders"
          :loading="loading"
          single-expand
          :expanded.sync="expanded"
          item-key="orderId"
          show-expand
          :items-per-page="10"
          :footer-props="{
            showFirstLastPage: true,
            'items-per-page-options': [5, 10, 15],
          }"
          @item-expanded="(data) => getOrderBoxes(data)"
        >
          <template v-slot:top>
            <orders-delete
              :isOpen="dialogDeleteOrder"
              @close="dialogDeleteOrder = false"
            />
            <OrdersUpload
              v-model="dialogUpload"
              @uploadStarted="isLoading = true"
              @uploadFinished="
                isLoading = false;
                getDataFromApi;
              "
            />
            <v-dialog v-model="dialog" max-width="500px">
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-form ref="OrderForm">
                      <v-row>
                        <!-- <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.orderId"
                            label="Order ID"
                            :rules="[rules.required]"
                          />
                        </v-col> -->
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.productId"
                            label="Product ID"
                            :rules="[rules.required]"
                            :disabled="editedIndex > -1"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-autocomplete
                            v-model="editedItem.zoneId"
                            :items="zones"
                            item-text="name"
                            item-value="id"
                            label="Bay"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field v-model="editedItem.sku" label="SKU" />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.description"
                            label="Description"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.quantity"
                            label="Quantity"
                            :rules="[rules.required, rules.number]"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.picked"
                            label="Picked"
                            :rules="[rules.number]"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-select
                            v-model="editedItem.statusId"
                            :items="status_list"
                            item-text="statusInText"
                            item-value="id"
                            label="Status"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.instructions"
                            label="Instructions"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-autocomplete
                            v-model="editedItem.replacementProductId"
                            :items="products"
                            item-text="productName"
                            item-value="barcode"
                            label="Replacement Product"
                          />
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn color="blue darken-1" text @click="close">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline">
                  Are you sure you want to delete this item?
                </v-card-title>
                <v-card-actions>
                  <v-spacer />
                  <v-btn color="blue darken-1" text @click="closeDelete">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">
                    OK
                  </v-btn>
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-dialog> -->
            <v-dialog v-model="dialogFtpUpdate" max-width="500px">
              <v-card>
                <v-card-title class="headline">
                  Are you sure you want to update to the SFTP?
                </v-card-title>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="dialogFtpUpdate = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="GenerateStatusReport"
                  >
                    OK
                  </v-btn>
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ dateFormat(item.createdAt) }}
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-chip
                  class="ma-2"
                  text-color="white"
                  :v-if="getStatus(item.status)"
                  :color="getStatus(item.status)"
                  v-on="on"
                >
                  {{
                    item.status
                      ? status_list.find((x) => x.id == item.status)
                          .statusInText
                      : ""
                  }}
                </v-chip>
              </template>
              <span>{{ statusFullForm(item.status ? item.status : 0) }}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              v-if="canEditOrder"
              medium
              color="info"
              class="mr-2"
              @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon medium color="error" @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="primary pa-1">
              <OrdersBoxesTable
                v-model="item.boxes"
                :status_list="status_list"
                :zones="zones"
                :loading="boxLoading"
              />
            </td>
          </template>
        </v-data-table>
        <OrdersBoxesTable
          v-else-if="isViewTable('boxes')"
          :search_field="fieldName"
          :search_value="completedSearch"
          :status_list="status_list"
          :zones="zones"
        />
        <OrdersLinesTable
          v-else-if="isViewTable('lines')"
          :search_field="fieldName"
          :search_value="completedSearch"
          :status_list="status_list"
          :zones="zones"
        />
      </v-card-text>
    </material-card>
  </v-container>
</template>
<script>
import OrdersDelete from "./components/OrdersDelete.vue";
import OrderDataService from "@/services/OrderDataService";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import OrdersLinesTable from "./components/OrdersLinesTable.vue";
import OrdersBoxesTable from "./components/OrderBoxesTable.vue";
import OrdersUpload from "./components/DialogUpload.vue";
import moment from "moment";

export default {
  name: "Orders",
  components: {
    Loading,
    OrdersBoxesTable,
    OrdersLinesTable,
    OrdersDelete,
    OrdersUpload,
  },
  data: () => ({
    permissions: localStorage.getItem("permissions"),
    dialogFtpUpdate: false,
    productTable: false,
    sampleProductTable: false,
    loading: false,
    boxLoading: false,
    orderLineLoading: false,
    isLoading: false,
    loadingSize: 128,
    loadingFullPage: false,
    rules: {
      required: (value) => !!value || "Required.",
      number: (value) =>
        Number.isInteger(Number(value)) || "The value must be an number.",
    },
    isSearch: false,
    expanded: [],
    boxExpanded: [],
    isExpand: false,
    singleExpand: true,
    dialog: false,
    dialogDelete: false,
    dialogUpload: false,
    dialogCsvErrors: false,
    dialogBox: false,
    dialogSampleProductUpload: false,
    dialogSampleProductForCsv: false,
    dialogDeleteOrder: false,
    ordersHeaders: [
      {
        text: "Invoice Number",
        value: "orderId",
        sortable: false,
      },
      {
        text: "Status",
        align: "center",
        value: "status",
        sortable: false,
      },
      {
        text: "No of boxes",
        align: "center",
        value: "noOfOrderBoxes",
        sortable: false,
      },
      {
        text: "Invoice lines",
        align: "center",
        value: "noOfOrderLines",
        sortable: false,
      },
      {
        text: "Csv name",
        value: "csvName",
        sortable: false,
      },
      {
        text: "Created at",
        value: "createdAt",
        sortable: false,
      },
    ],
    totalOrders: 0,
    options: {},
    search: "",
    completedSearch: "",
    awaitingSearch: false,
    triggerSearch: false,
    orders: [],
    currentType: "",
    currentOrderId: "",
    currentOrderBoxId: "",
    zones: [],
    csvErrorLines: [],
    status_list: [],
    products: [],
    selectedBoxes: [],
    orderCSVFile: [],
    editedIndex: -1,
    editedItem: {
      orderId: "",
      productId: "",
      zoneId: "",
      sku: "",
      description: "",
      quantity: "",
      picked: 0,
      statusId: 1,
      pickingDateTime: "",
      replacementProductId: "",
      instructions: "",
    },
    defaultItem: {
      orderId: "",
      productId: "",
      zoneId: "",
      sku: "",
      description: "",
      quantity: "",
      picked: 0,
      statusId: 1,
      pickingDateTime: "",
      replacementProductId: "",
      instructions: "",
    },
    fieldName: "OrderId",
    fields: [
      {
        text: "Invoice Number",
        value: "OrderId",
      },
      {
        text: "Csv Name",
        value: "CsvName",
      },
      {
        text: "Status",
        value: "Status",
      },
      {
        text: "Box Number",
        value: "OrderBoxId",
      },
      {
        text: "Box Status",
        value: "OrderBoxStatus",
      },
      {
        text: "Product ID",
        value: "ProductId",
      },
      {
        text: "SKU",
        value: "SKU",
      },

      {
        text: "Order Line Status",
        value: "OrderProductStatus",
      },
    ],

    searchTimerId: null,
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add New Order" : "Edit Order";
    },
    boxFormTitle() {
      return this.editedIndex === -1 ? "Add New Order" : "Edit Order";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      handler() {
        if (!this.options.isSearch) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
    search: {
      handler() {
        this.completedSearch = "";
      },
    },
  },

  created() {
    this.$ordersHub.$on("status-changed", this.onStatusChanged);

    this.$http.get("Statuses").then((response) => {
      this.status_list = response.data;
    });

    this.$http.get("Bays").then((response) => {
      this.zones = response.data.data;
    });
  },
  beforeDestroy() {
    this.$ordersHub.$off("status-changed", this.onStatusChanged);
  },
  methods: {
    getStatus(id) {
      if (id && this.status_list) {
        let status = this.status_list.find((x) => x.id == id);
        if (status) {
          return status.code;
        }
      }
      return null;
    },
    isViewTable(table) {
      if (this.completedSearch) {
        if (
          table === "orders" &&
          (this.fieldName === "CsvName" ||
            this.fieldName === "Status" ||
            this.fieldName === "OrderId")
        ) {
          return true;
        } else if (
          table === "boxes" &&
          (this.fieldName === "OrderBoxId" ||
            this.fieldName === "OrderBoxStatus")
        ) {
          return true;
        } else if (
          table === "lines" &&
          (this.fieldName === "ProductId" ||
            this.fieldName === "SKU" ||
            this.fieldName === "OrderProductStatus")
        ) {
          return true;
        }
        return false;
      } else {
        return table === "orders";
      }
    },
    dateFormat(date) {
      if (date) {
        return moment.utc(date).local().format("h:mm:ss a DD.MM.YYYY");
      } else {
        return "";
      }
    },
    onStatusChanged(data) {
      let index = this.orders.findIndex((x) => x.orderId === data.OrderId);

      if (index > -1) {
        let obj = this.orders[index];

        if (data.Status === 4) {
          obj.status = 4;
        } else {
          let boxesLength = obj.boxes.length;
          let pickedBoxesLength = obj.boxes.filter(
            (x) => x.status === 3
          ).length;

          if (boxesLength === pickedBoxesLength) {
            obj.status = 3;
          }
        }

        this.orders.splice(index, 1, obj);
      }
    },

    getDataFromApi() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      if (
        !this.completedSearch ||
        (this.completedSearch && this.fieldName === "CsvName") ||
        "Status" ||
        "OrderId"
      ) {
        var data = {
          pageNumber: page,
          pageSize: itemsPerPage,
          search: this.completedSearch,
          searchColumn: this.fieldName,
        };

        this.$http
          .post("Orders/Paginated", data)
          .then((response) => {
            let orders = response.data.data.items;
            if (orders) {
              this.orders = orders.map((x) => {
                x["boxes"] = [];
                return x;
              });
            }
            this.totalOrders = response.data.data.totalCount;
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },

    getOrderBoxes(data) {
      if (data.value && !(data.item.boxes && data.item.boxes.length > 0)) {
        this.boxLoading = true;
        let orderId = data.item.orderId;
        if (orderId) {
          this.$http
            .post(`Orders/${orderId}/Boxes`)
            .then((response) => {
              let boxes = response.data.data;
              if (boxes) {
                data.item.boxes = boxes.map((x) => {
                  x["orderLines"] = [];
                  return x;
                });
                this.getInstruction(data.item.boxes);
              }
              this.boxLoading = false;
            })
            .catch(() => {
              this.boxLoading = false;
            });
        }
      }
    },
    getInstruction(boxes) {
      boxes.forEach((box) => {
        this.$http
          .post(`Orders/Boxes/${box.orderBoxId}/Instructions`)
          .then((response) => {
            let _instructions = response.data.data;
            if (_instructions && _instructions.length > 0) {
              let boxInstructions = [];
              _instructions.forEach((x) => {
                if (
                  !boxInstructions.some(
                    (y) =>
                      y.bayNumber === x.bayNumber &&
                      y.instruction === x.instruction
                  )
                ) {
                  boxInstructions.push({
                    bayNumber: x.bayNumber,
                    instruction: x.instruction,
                  });
                }
              });
              box.instructions = boxInstructions;
            } else {
              box.instructions = [];
            }
          });
      });
    },
    addSampleProduct(item) {
      OrderDataService.addProduct(item).then((response) => {
        // this.orders.push(this.editedIndex);
        this.getDataFromApi();
        this.$toast.success("Order added successfully.", "Success", {
          position: "topRight",
        });
      });
    },
    editItem(item) {
      if (this.isSearch) {
        this.editedIndex = 0;
      } else {
        this.editedIndex = 0;
      }
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      // when searching item doesn't have parent item
      // when grouped format editedIndex is parentItem index
      if (this.isSearch) {
        this.editedIndex = this.orders.indexOf(item);
      } else {
        this.editedIndex = this.orders.indexOf(item);
      }
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
      this.currentType = "product";
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.$refs.OrderForm.validate()) {
        this.orders.forEach((x) => {
          x.boxes.forEach((y) => {
            if (y.orderBoxId == this.editedItem.orderId) {
              this.editedItem.orderId = x.orderId;
            }
          });
        });
        OrderDataService.update(this.editedItem).then((response) => {
          this.getDataFromApi();
          this.$toast.success("Order product edited successfully.", "Success", {
            position: "topRight",
          });
        });
      }
      this.close();
    },

    groupStatus(products) {
      // statusId = 1 = WAIT, 2= PPKD, 3=PIKD, 4=PRCS
      if (products.some((x) => x.statusId == 1)) {
        // if any of a product statusID = 1 that means wait or partially picked
        if (products.some((x) => x.statusId > 1)) {
          // partially picked
          return 2;
        } else {
          // wait
          return 1;
        }
      } else if (products.some((x) => x.statusId == 2)) {
        // partially picked
        return 2;
      } else if (products.some((x) => x.statusId == 3)) {
        // if any of a product statusID = 3 that means partially picked or picked
        if (products.some((x) => x.statusId < 3)) {
          // partially picked
          return 2;
        } else {
          // picked
          return 3;
        }
      } else {
        for (let i = 1; i <= 7; i++) {
          if (products.some((x) => x.statusId == i)) {
            return i;
            break;
          }
        }
      }
    },

    statusFullForm(id) {
      var status = "";
      switch (id) {
        case 1:
          status = "Waiting to be picked";
          break;
        case 2:
          status = "Partially picked";
          break;
        case 3:
          status = "Order/line picked";
          break;
        case 4:
          status = "Order passed Final Station";
          break;
        case 5:
          status = "Order completed at the warehouse";
          break;
        case 6:
          status = "Order dispatched from the warehouse";
          break;
        case 7:
          status = "Order delivered to the customer";
          break;
      }
      return status;
    },

    openDialogBox(id) {
      this.dialogBox = true;
      this.currentOrderId = id;
    },

    addBox() {
      var data = {
        orderBoxId: this.currentOrderBoxId,
        orderId: this.currentOrderId,
      };
      OrderDataService.addBox(data).then((response) => {
        // this.orders.push(this.editedIndex);
        this.getDataFromApi();
        this.$toast.success("Box added successfully.", "Success", {
          position: "topRight",
        });
      });
      this.dialogBox = false;
    },

    deleteOrder(item) {
      this.currentOrderId = item.orderId;
      this.currentType = "order";
      this.dialogDelete = true;
    },

    deleteBox(item) {
      this.currentOrderBoxId = item.orderBoxId;
      this.currentType = "box";
      this.dialogDelete = true;
    },

    addSampleProductDialog() {
      if (this.selectedBoxes.length > 0) {
        this.dialogSampleProductUpload = true;
      } else {
        this.$toast.error("There are no boxes selected.", "Error", {
          position: "topRight",
        });
      }
    },

    GenerateStatusReport() {
      OrderDataService.GenerateStatusReport()
        .then((response) => {
          if (response.status == 200) {
            this.$toast.success(
              "Report updated to FTP successfully.",
              "Success",
              {
                position: "topRight",
              }
            );
          } else {
            this.$toast.error(response.data, "Error", {
              position: "topRight",
            });
          }
        })
        .catch((error) => {
          this.$toast.error(error.response.data, "Error", {
            position: "topRight",
          });
        });

      this.dialogFtpUpdate = false;
    },

    searchFunction() {
      if (this.search) {
        this.completedSearch = this.search.trim();
        this.options.page = 1;
        this.options.isSearch = true;
        this.getDataFromApi(this.completedSearch);
      }
    },
  },
};
</script>
